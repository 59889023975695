@charset "utf-8";

// Import our inter fonts
@import url("./fonts/inter.css");

// Update some of Bulma's component variables
$body-background-color: #fff;
$body-family: "Inter";

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';

@import '../node_modules/bulma/sass/grid/_all.sass';

@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';

@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/modal.sass';

@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';


// Custom Styles

.navbar {
  background-color: #649482;
  .logo-copy {
    &:hover, &:focus {
      color: white; }
    color: white; } }

.app-body {
  line-height: 1.2;
  margin: 10px 10px 0px 10px;

  h1 {
    font-size: 14pt;
    font-weight: bold; }

  .card-content {
    h1 {
      margin-bottom: 10px; } }

  .item.card {
    min-height: 200px;
    overflow-y: hidden; } }

.pageHeader {
  margin-bottom: 22px;
  h1 {
    font-size: 22pt; } }

.clickable-card:hover {
  cursor: pointer; }

// Article Specific Styles
.article-page {
  h1 {
    font-size: 2.5rem; }
  h2 {
    font-size: 2rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h1,h2,h3,h4 {
    font-weight: 500;
    margin-bottom: 0.5rem; }

  .article-content {
    line-height: 1.2;
    font-weight: 400;
    p {
      margin-bottom: 16px; }
    blockquote {
      padding: 5px 15px;
      background-color: rgba(229, 235, 232, 1.0);
      margin-bottom: 16px;
      p {
        margin: 0px; } }
    a {
      color: rgba(93, 135, 118, 1.0);
      text-decoration: underline; }
    h2 {
      color: rgba(68, 121, 100, 1.0); }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 8px;
      font-weight: 500; }
    ul {
      list-style: inside;
      padding-left: 1.0rem;
      margin-bottom: 16px;
      li {
        padding-top: 0.5rem; } }
    ol {
      padding-left: 1.0rem;
      li {
        padding-top: 0.5rem; } } } }

// Glossary Specific Modal Styles

.glossary.modal {
  .modal-content {
    padding: 10px;

    .box {
      min-height: 100px;
      padding: 10px; } } }
